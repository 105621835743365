<template>
  <a-drawer
    v-model:visible="visible"
    :maskClosable="false"
    :keyboard="false"
    width="100%"
    :title="state.title"
  >
    <div class="detail" v-if="!state.isRemark">
      <div class="title-wrap">{{ $t("lecturer.basic_info") }}</div>
      <a-form
        class="edit-form-wrap"
        layout="vertical"
        ref="formRef"
        :model="formData"
      >
        <a-row :gutter="24">
          <template v-for="(item, index) in state.editFields" :key="index">
            <template v-if="item.defaultField == 1">
              <a-col :span="12" v-if="item.key == 'planName'">
                <a-form-item :label="item.name" name="planName">
                  <a-input
                    :maxlength="50"
                    v-model:value="formData.planName"
                    :disabled="disabled"
                  />
                </a-form-item>
              </a-col>
              <a-col
                :span="12"
                v-if="
                  item.key == 'demandCollection' &&
                  state.companyInfo.menu.includes('需求征集管理')
                "
              >
                <a-form-item :label="item.name" name="demandCollectionName">
                  <a-input
                    v-model:value="formData.demandCollectionName"
                    disabled
                  />
                </a-form-item>
              </a-col>
              <a-col
                :span="12"
                v-if="
                  item.key == 'demand' &&
                  state.companyInfo.menu.includes('需求征集管理')
                "
              >
                <a-form-item :label="item.name" name="planName">
                  <a-select
                    v-model:value="formData.demandIds"
                    mode="multiple"
                    :open="false"
                    style="width: 100%"
                    disabled
                  >
                    <a-select-option
                      :value="item.demandId"
                      v-for="item in state.demands"
                      :key="item.demandId"
                    >
                      {{ item.trainName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12" v-if="item.key == 'hostDept'">
                <a-form-item :label="item.name" name="hostDepartmentId">
                  <a-input
                    v-model:value="formData.hostDepartmentName"
                    disabled
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12" v-if="item.key == 'implementDept'">
                <a-form-item :label="item.name" name="implementDepartmentId">
                  <a-input
                    v-model:value="formData.implementDepartmentName"
                    disabled
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12" v-if="item.key == 'trainingTime'">
                <a-form-item :label="item.name" name="trainingTime">
                    <!-- 年 -->
                    <a-select
                      v-if="state.planTrainingTimeFormat === 'year'"
                      v-model:value="formData.planTime"
                      :options="yearOp"
                      :disabled="disabled"
                    >
                    </a-select>
                    <!-- 年/月 -->
                    <div class="flex" v-if="state.planTrainingTimeFormat === 'month'">
                      <a-select
                        style="width: 100px;"
                        v-model:value="formData.customPlanYear"
                        :options="[]"
                        disabled
                      >
                      </a-select>
                      <a-select
                        v-model:value="formData.planMonth"
                        mode="multiple"
                        allowClear
                        :options="[]"
                        disabled
                      >
                      </a-select>
                    </div>
                    <!-- 年/月/日 -->
                    <a-range-picker
                      v-if="state.planTrainingTimeFormat=== 'day'"
                      allowClear
                      v-model:value="formData.trainingTime"
                      format="YYYY-MM-DD HH:mm"
                      valueFormat="YYYY-MM-DD HH:mm"
                      style="width: 100%"
                      disabled
                    >
                      <template #suffixIcon>
                        <CalendarOutlined />
                      </template>
                    </a-range-picker>
                </a-form-item>
              </a-col>
              <a-col :span="12" v-if="item.key == 'budgetAmount'">
                <a-form-item :label="item.name" name="budgetAmount">
                  <a-input-number
                    style="width: calc(100% - 130px)"
                    :min="0"
                    :precision="2"
                    v-model:value="formData.budgetAmount"
                    :disabled="disabled"
                  />
                  {{ $t(item.dataFormat === 'yuan' ? 'demand.yuan' : 'TenThousandYuan') }}
                </a-form-item>
              </a-col>
            </template>
            <template v-else>
              <a-col :md="12" :sm="24">
                <!-- 1:文本框 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-if="item.type == 1"
                >
                  <a-input
                    v-model:value="formData[item.key]"
                    allowClear
                    :maxlength="50"
                    :disabled="disabled"
                  />
                </a-form-item>
                <!-- 2:下拉框 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-else-if="item.type == 2"
                >
                  <a-select
                    allowClear
                    labelInValue
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    v-model:value="formData[item.key]"
                    :options="item.fieldOptions"
                    :disabled="disabled"
                  />
                </a-form-item>
                <!-- 4:数值 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-else-if="item.type == 4"
                >
                  <a-input-number
                    allowClear
                    v-model:value="formData[item.key]"
                    :precision="item.len"
                    style="width: 100%"
                    :disabled="disabled"
                  />
                </a-form-item>
                <!-- 5:日期组件 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-else-if="item.type == 5"
                >
                  <a-date-picker
                    :getCalendarContainer="
                      (triggerNode) => triggerNode.parentNode
                    "
                    valueFormat="YYYY-MM-DD"
                    v-model:value="formData[item.key]"
                    allowClear
                    style="width: 100%"
                    :disabled="disabled"
                  />
                </a-form-item>
                <!-- 6:文本域 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-if="item.type == 6"
                >
                  <a-textarea
                    v-model:value="formData[item.key]"
                    allowClear
                    :maxlength="500"
                    showCount
                    :disabled="disabled"
                  />
                </a-form-item>
                <!-- 7:时间组件 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-else-if="item.type == 7"
                >
                  <a-date-picker
                    :getCalendarContainer="
                      (triggerNode) => triggerNode.parentNode
                    "
                    valueFormat="YYYY-MM-DD HH:mm"
                    :show-time="{ format: 'HH:mm' }"
                    format="YYYY-MM-DD HH:mm"
                    v-model:value="formData[item.key]"
                    allowClear
                    style="width: 100%"
                    :disabled="disabled"
                  />
                </a-form-item>
                <!-- 8:树 -->
                <a-form-item
                  :name="item.key"
                  :label="item.name"
                  v-else-if="item.type == 8"
                >
                  <a-tree-select
                    allowClear
                    labelInValue
                    v-model:value="formData[item.key]"
                    style="width: 100%"
                    :dropdown-style="{ maxHeight: '30vh', overflow: 'auto' }"
                    :tree-data="item.fieldOptions"
                    treeNodeFilterProp="title"
                    :multiple="item.isCheck == 1"
                    :treeCheckable="item.isCheck == 1"
                    :showCheckedStrategy="
                      item.isSelectChild == 1 ? 'SHOW_CHILD' : 'SHOW_ALL'
                    "
                    :replaceFields="{
                      children: 'children',
                      title: 'optionValue',
                      key: 'optionKey',
                      value: 'optionKey',
                    }"
                    treeDefaultExpandAll
                    :disabled="disabled"
                  ></a-tree-select>
                </a-form-item>
              </a-col>
            </template>
          </template>
        </a-row>
      </a-form>
      <div class="title-wrap">{{ $t("CM_LB_AttachmentFile") }}</div>
      <div class="file" v-if="formData.fileList">
        <div
          class="file-item" v-for="item in formData.fileList"
          :key="item.file"
          @click="viewOrDownFile(item)"
        >
          <span class="icon"><LinkOutlined /> </span>
          {{ item.fileName }}.{{ item.suffix }}
        </div>
      </div>
      <div class="title-wrap">{{ $t("ProgramDetails") }}</div>
    </div>
    <div class="table" :class="state.isRemark && 'remark-table'">
      <a-table
        size="small"
        :scroll="{ x: 884 }"
        :columns="state.subPlanColumns"
        rowKey="subPlanId"
        :data-source="state.dataList"
        :pagination="false"
      >
        <template #index="{ index }">
          {{ index + 1 }}
        </template>
        <template #tooltip="{ text }">
          <a-tooltip :title="text">{{ text }}</a-tooltip>
        </template>
        <template #trainingTime="{ text, record }">
          <template v-if="text && text.length">
            <a-tooltip :title="text[0] + '~' + text[1]"
              >{{ text[0] }} ~ {{ text[1] }}</a-tooltip
            >
          </template>
          <template v-else>
            <span :title="record.subPlanTime">{{ record.subPlanTime }}</span>
          </template>
        </template>
        <template #teachers="{ record }">
          <a-tooltip placement="topLeft">
            <template #title>
              <template v-for="(item, index) in record.teacherList" :key="index">
                <span class="pr6">{{ item.lecturerName }}</span>
              </template>
            </template>
            <div class="w160 ellipsis">
              <template v-for="(item, index) in record.teacherList" :key="index">
                <span class="pr6">{{ item.lecturerName }}</span>
              </template>
            </div>
          </a-tooltip>
        </template>
        <template #responsibles="{ record }">
          <a-tooltip placement="topLeft" :title="record.responsibleNames">
            {{ record.responsibleNames }}
          </a-tooltip>
        </template>
        <template #courseName="{ text, record }">
          <a-tooltip placement="topLeft" :title="text || record.courseNames">
            {{ text || record.courseNames }}
          </a-tooltip>
        </template>
        <template #budgetAmount="{ text }">
          {{ state.subConfig.budgetAmount === 'wanyuan' ? (text / 10000).toFixed(2) : text }}
          {{ $t(state.subConfig.budgetAmount === 'yuan' ? 'demand.yuan' : 'TenThousandYuan') }}
        </template>
        <template #tooltip_json="{ text }">
          <template v-if="text">
            <template v-if="Array.isArray(JSON.parse(text || '{}'))">
              <a-tooltip
                :title="
                  JSON.parse(text)
                    .map((o) => o.label)
                    .join(',')
                "
              >
                {{
                  JSON.parse(text)
                    .map((o) => o.label)
                    .join(",")
                }}
              </a-tooltip>
            </template>
            <a-tooltip v-else :title="JSON.parse(text || '{}').label">{{
              JSON.parse(text || "{}").label
            }}</a-tooltip>
          </template>
        </template>
        <template #action="{ record, index }">
          <a class="btn" src="javascript" @click="editRemark(record, index)">{{ $t('credits.remark') }}</a>
          <a-tooltip>
            <template #title>
              <div class="preformatted-text">{{ record.auditRemark }}</div>
            </template>
            <div class="ellipsis-tooltip">{{ record.auditRemark }}</div>
          </a-tooltip>
        </template>
      </a-table>
    </div>
    <div class="footer">
      <!-- 取消 -->
      <a-button @click="visible = false">{{ $t("CM_Cancel") }}</a-button>
    </div>
    <!-- 备注 -->
    <a-modal
      :title="$t('credits.remark')"
      v-model:visible="state.showRemark"
      :maskClosable="false"
      :keyboard="false"
      :closable="false"
      width="600px"
      @ok="saveRemark"
      @cancel="state.showRemark = false"
    >
      <a-textarea
        v-model:value="remarkParams.auditRemark"
        :maxlength="500"
        :auto-size="{ minRows: 4 }"
        allowClear
        showCount
        :placeholder="$t('LB_QA_PleaseEnter') + $t('credits.remark')"
      />
    </a-modal>
  </a-drawer>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance } from "vue";
import ls from "@/utils/local-storage";
import { getPlanField, editSubPlanRemark, getPlanDetail } from "@/api/auditCenter";
import { viewOrDownFile } from "@/utils/tools";

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const visible = ref(false);

const state = reactive({
  editFields: [],
  demands: [],
  subPlanColumns: [
    {
      // title: "序号",
      title: $t("map.table.serial"),
      dataIndex: "index",
      width: 60,
      ellipsis: true,
      slots: { customRender: "index" },
    },
  ],
  dataList: [],
  companyInfo: {
    menu: [],
  },
  planBudgetAmountUnit: 'wanyuan', // 预算单位
  planTrainingTimeFormat: 'day', // 培训时间类型
  subConfig: {
    budgetAmount: 'wanyuan',
    trainingTime: 'day',
    courseName: 1,
    courseHour: 1,
  },
  isRemark: false, //备注修改意见
  showRemark: false,
  currentPlan: -1,
  title: '',
});
const formData = ref({
  planId: 0,
  secretData: 0,
  planName: "",
  demandCollectionId: 0,
  demandCollectionName: "",
  demandIds: [],
  hostDepartmentId: "",
  implementDepartmentId: "",
  trainingTime: [],
  budgetAmount: 0,
});
const disabled = ref(true);

async function show(id, isRemark) {
  state.isRemark = isRemark || false;
  state.title = state.isRemark ? $t('RemarksModifications') :$t('TrainingProgramDetails')
  state.companyInfo = ls.get("companyInfo") || {};
  visible.value = true;
  await initData();
  let data = {};
  const res = await getPlanDetail(id);
  if (res.ret === 0) {
    data = res.data;
  } else {
    return false;
  }
  state.title += `--${data.planName}`;
  state.dataList = data.subPlanList || [];
  if (isRemark) return;
  data.customFieldMap = data.customFieldMap || {};
  for (var key in data.customFieldMap) {
    data[key] = data.customFieldMap[key];
  }
  if (data.budgetAmount && state.planBudgetAmountUnit === 'wanyuan') {
    data.budgetAmount = data.budgetAmount / 10000;
  }
  if (data.trainingTime && data.trainingTime.length) {
    state.planTrainingTimeFormat = 'day';
  }
  if (data.planTime) {
    const isMonth = data.planTime.indexOf('/') !== -1;
    state.planTrainingTimeFormat = isMonth ? 'month' : 'year';
    if (isMonth) {
      const date = data.planTime.split(',');
      data.planMonth = date.map(m => {
        const e = m.split('/');
        if (!data.customPlanYear) data.customPlanYear = e[0] + '';
        return e[1];
      });
    }
  }
  formData.value = data;
  let demandList = data.demandList || [];
  state.demands = [];
  formData.value.demandIds = [];
  demandList.forEach((item) => {
    formData.value.demandIds.push(item.demandId);
    state.demands.push(item);
  });
}
function isLeaf (arr, type) {
  arr.forEach(item => {
    if (item.children?.length) {
      item.selectable = false;
      isLeaf(item.children, type);
    }
  });
};
async function initData() {
  const res = await getPlanField();
  if (res.ret !== 0) return false;
  if (!state.isRemark) {
    let editFields = res.data.plan.editFields || [];
    editFields.forEach((item) => {
      if (item.defaultField == 2) {
        if (item.type == 2) {
          item.fieldOptions.map((opt) => {
            opt.label = opt.optionValue;
            opt.value = opt.optionKey;
          });
        }
        if (item.type == 8 && item.isSelectChild == 1) {
          if (item.isCheck == 1) {
            isLeaf(item.fieldOptions, 'selectable');
          } else {
            isLeaf(item.fieldOptions, 'disabled');
          }
        }
        if (item.type == 4 && formData.value[item.key]) {
          formData.value[item.key] = Number(formData.value[item.key]);
        }
        if ([2, 8].includes(item.type) && formData.value[item.key]) {
          formData.value[item.key] = JSON.parse(formData.value[item.key]);
        }
      }
      if (item.key === 'budgetAmount' && item.dataFormat) {
        state.planBudgetAmountUnit = item.dataFormat;
      }
      if (item.key === 'trainingTime' && item.dataFormat) {
        state.planTrainingTimeFormat = item.dataFormat;
      }
    });
    state.editFields = editFields;
  }

  let subEditFields = res.data.subPlan.editFields || [];
  state.subPlanColumns = [
    {
      title: $t("map.table.serial"),
      dataIndex: "index",
      width: 60,
      ellipsis: true,
      slots: { customRender: "index" },
    },
  ];
  subEditFields.forEach((item) => {
    if (item.defaultField == 2) {
      if (item.type == 2) {
        item.fieldOptions.map((opt) => {
          opt.label = opt.optionValue;
          opt.value = opt.optionKey;
        });
      }
      // if (item.type == 8 && item.isSelectChild == 1) {
      //   if (item.isCheck == 1) {
      //     isLeaf(item.fieldOptions, 'selectable');
      //   } else {
      //     isLeaf(item.fieldOptions, 'disabled');
      //   }
      // }
      let tplName = "tooltip";
      if ([2, 8].includes(item.type)) {
        tplName = "tooltip_json";
      }
      state.subPlanColumns.push({
        title: item.name,
        dataIndex: "customFieldMap." + item.key,
        slots: { customRender: tplName },
        width: 180,
        sorter: false,
        ellipsis: true,
        align: 'center',
      });
    } else {
      state.subPlanColumns.push({
        title: item.name,
        dataIndex: item.key,
        slots: { customRender: item.key },
        width: item.key === 'trainingTime' ? 300 : 180,
        sorter: false,
        ellipsis: true,
        align: 'center',
      });
    }
    if (item.key === 'budgetAmount' && item.dataFormat) {
      state.subConfig.budgetAmount = item.dataFormat;
    }
    if (item.key === 'trainingTime' && item.dataFormat) {
      state.subConfig.trainingTime = item.dataFormat;
    }
    if (item.key === 'courseName' && item.dataSource) {
      state.subConfig.courseName = item.dataSource;
    }
    if (item.key === 'courseHour' && item.dataSource) {
      state.subConfig.courseHour = item.dataSource;
    }
  });
  if (state.isRemark) {
    state.subPlanColumns.push({
      title: $t('cm_operate'),
      dataIndex: 'action',
      slots: { customRender: 'action' },
      width: 180,
      fixed: 'right',
      ellipsis: true,
      align: 'center',
    });
  }
}

const remarkParams = reactive({
  planId: 0,
  subPlanId: 0,
  auditRemark: '',
})
function editRemark(data, index) {
  state.showRemark = true;
  state.currentPlan = index;
  remarkParams.planId = data.planId;
  remarkParams.subPlanId = data.subPlanId;
  remarkParams.auditRemark = data.auditRemark;
}
async function saveRemark() {
  if (!remarkParams.auditRemark) {
    return proxy.$message.error($t('LB_QA_PleaseEnter') + $t('credits.remark'));
  }
  const res = await editSubPlanRemark(remarkParams);
  if (res.ret === 0) {
    proxy.$message.success($t('CM_Success'));
    state.dataList[state.currentPlan].auditRemark = remarkParams.auditRemark;
    state.showRemark = false;
  } else {
    proxy.$message.error(res.msg);
  }
}

defineExpose({
  show,
});
</script>
<style lang="less" scoped>
.detail {
  width: 950px;
  margin: 0 auto;
  .title-wrap {
    position: relative;
    padding: 10px;
    font-size: 16px;
    &::before {
      content: "";
      position: absolute;
      top: 16px;
      left: -2px;
      height: 16px;
      width: 4px;
      background-color: var(--theme);
    }
  }
}
.file {
  &-item {
    margin-bottom: 5px;
    padding: 2px 5px;
    color: var(--theme);
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .icon {
      padding: 0 3px;
      color: #666;
    }
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
.table {
  width: 950px;
  margin: 0 auto;
  padding-bottom: 80px;
}
.remark-table {
  width: 100%;
  .btn {
    color: var(--theme);
  }
}
.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  padding: 10px 0;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #ccc;
}
.pr6 {
  padding-right: 6px;
}
.w160 {
  width: 160px;
}
.flex {
  display: flex;
}
// 文本域格式
.preformatted-text {
  white-space: pre-wrap;
}
.ellipsis-tooltip {
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
</style>
